import React, { useEffect } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

function App() {

  useEffect(() => {
    if (isMobile) {
      if (isAndroid)
        window.location.href = "https://play.google.com/store/apps/details?id=com.qwrtx.mmagoat"
      else if (isIOS)
        window.location.href = "https://apps.apple.com/us/app/mma-goat/id6477226190"
      else
        window.location.href = "https://mma-goat.com/"
    } else
      window.location.href = "https://mma-goat.com/"
    // eslint-disable-next-line
  }, []);

  return (
    <p>Redirecting to store... Please wait</p>
  );
}

export default App;
